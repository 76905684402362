<template>
  <div class="mt-1" v-loading="loadingData">
    <div class="nurseOffice">
      <!-- <div class="mt10 mb-3">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">Cтационар</el-breadcrumb-item>
          <el-breadcrumb-item>Кабинет медсестра</el-breadcrumb-item>
        </el-breadcrumb>
      </div>-->
      <div class="title_resultsPatsnt">
        <div class="title_resultsPatsnt_text">{{ $t('message.patient_physiotherapy') }}</div>
        <el-button v-if="lastHistory.is_ended != 0" disabled v-can="'physiotherapies.create'" size="mini" @click="drawer.create.status = true"
          icon="el-icon-circle-plus-outline">
          {{ $t('message.add_physiotherapy') }}
        </el-button>
        <el-button v-else v-can="'physiotherapies.create'" size="mini" @click="drawer.create.status = true"
          icon="el-icon-circle-plus-outline">
          {{ $t('message.add_physiotherapy') }}
        </el-button>
      </div>
      <div class="nurseOffice_tabs overflow-hidden pl-4 pr-4">
        <Medicine />
      </div>

    </div>

    <el-drawer size="100%" :visible.sync="drawer.create.status" :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)" @closed="drawerClosed(drawer.create.component)">
      <crm-create :ref="drawer.create.component" :drawer-name="drawer.create.name"></crm-create>
    </el-drawer>
  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import CrmCreate from "./components/addPhys";
  import list from "@/utils/mixins/list";
  import Medicine from "./components/physiotherapyMedicine";
  import drawer from "@/utils/mixins/drawer";

  export default {
    mixins: [list, drawer],
    name: "physiotherapy",
    components: {
      Medicine,
      CrmCreate
    },
    data() {
      return {
        loadingData: false,
        selectedModel: {},
        drawer: {
          create: {
            name: "create",
            status: false,
            component: 'componentDrawerCreate'
          }
        }
      }
    },
    computed: {
      getId() {
        return this.$route.params.id;
      },
      ...mapGetters({
        activeCode: 'hospitalPatients/activeCode',
        list: 'physiotherapies/list',
        columns: "physiotherapies/columns",
        pagination: "physiotherapies/pagination",
        filter: "physiotherapies/filter",
        sort: "physiotherapies/sort",
        physiotherapy: 'physiotherapies/model',
        lastHistory: 'dailyTreatments/lastHistory',
      }),
      actions: function () {
        return ['edit', 'delete'];
      }
    },
    watch: {
      lastHistory: {
        handler: function (val, oldVal) {
          this.updateList({
              hospital_patient_id: this.getId,
              id: val.id
            })
            .then(res => {
            }).catch(err => {
              this.$alert(err)
            });
        }
      },
    },
    async mounted() {
      this.loadingData = true;
      var vm = this;
      if (_.isArray(this.lastHistory) && _.size(this.lastHistory)) {
        this.updateList({
            hospital_patient_id: vm.getId,
            id: this.lastHistory.id
          })
          .then(res => {
            this.loadingData = false;
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
      }

    },
    methods: {
      ...mapActions({
        updateList: 'physiotherapies/index',
        updateSort: "physiotherapies/updateSort",
        updateFilter: "physiotherapies/updateFilter",
        updateColumn: "physiotherapies/updateColumn",
        updatePagination: "physiotherapies/updatePagination",
        editModel: 'physiotherapies/show',
        empty: 'physiotherapies/empty',
        delete: 'physiotherapies/destroy',
        refreshData: 'physiotherapies/refreshData',
      }),
      fetchData() {
        if ((_.isArray(this.lastHistory) && this.lastHistory.length > 0) || this.lastHistory.id) {
            this.loadingData = true;
          this.updateList({
              hospital_patient_id: this.getId,
              id: this.lastHistory.id
            })
            .then(res => {
              this.loadingData = false;
            }).catch(err => {
              this.loadingData = false;
              this.$alert(err)
            });
        }

      },
      refresh() {
        this.refreshData()
          .then(res => {
            this.filterForm = JSON.parse(JSON.stringify(this.filter));
          })
          .catch(err => {

          })
      },
      destroy(model) {
        this.delete(model.id)
          .then(res => {
            this.$alert(res);
            this.fetchData()
          })
          .catch(err => {
            console.log(err)
          })
      },
      emptyModel() {
        this.empty()
      },
    }
  };
</script>