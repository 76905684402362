<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.add_patient_physiotherapy") }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form ref="form" :model="form" class="aticler_m" size="small">
        <el-tabs type="border-card">
          <!-- end  el-tab-pane-->
          <el-tab-pane :label="$t('message.patient_physiotherapy')">
            <table
              class="indicators__table table table-bordered table-hover"
              v-for="(loop, index) in loops"
              :key="index + ' - patientIndicator'"
            >
              <!-- <i @click="removePhys(loop)" class="add_close_2  el-icon-circle-close"></i> -->
              <thead>
                <tr>
                  <th scope="col">{{ $t("message.service") }}</th>
                  <th scope="col" colspan="3">{{ $t("message.period") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <services
                      v-model="form.hospitalization_service_id"
                      size="mini"
                    ></services>
                  </td>
                  <td>
                    <el-date-picker
                      type="dates"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                      v-model="loop.end_date"
                      :placeholder="$t('message.period')"
                    ></el-date-picker>
                  </td>
                  <!-- <el-col :xs="12" :md="12" :lg="12">
                    <el-form-item label="Инструкция по применению" class="minheight">
                      <el-input type="textarea" v-model="loop.detail"></el-input>
                    </el-form-item>
                  </el-col> -->
                  <td>
                    <el-select
                      size="small"
                      v-model="loop.count"
                      :placeholder="$t('message.purpose_quantity')"
                    >
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                      <el-option label="4" value="4"></el-option>
                      <el-option label="5" value="5"></el-option>
                    </el-select>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div @click="appendPhys()" class="add_pol">Добавить Показатели пациента</div> -->
            <div class="grid-content bg-purple-light">
              <div class="button_r_2">
                <el-button
                  type="success"
                  :loading="loadingButton"
                  :disabled="loadingButton ? true : false"
                  @click="submit(true)"
                  >{{ $t("message.save_and_close") }}</el-button
                >
                <el-button @click="close()">{{
                  $t("message.close")
                }}</el-button>
              </div>
            </div>
          </el-tab-pane>
          <!-- end  el-tab-pane  -->
        </el-tabs>
        <!-- end  el-tab-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import services from "@/components/selects/services";

export default {
  mixins: [form, drawerChild],
  data() {
    return {
      form: {
        loops: [],
      },
      loops: [
        {
          hospitalizationService: [],
        },
      ],
    };
  },
  components: {
    services,
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "physiotherapies/rules",
      model: "physiotherapies/model",
      columns: "physiotherapies/columns",
      getId: "hospitalPatients/activeCode",
      lastHistory: "dailyTreatments/lastHistory",
    }),
  },
  methods: {
    ...mapActions({
      save: "physiotherapies/store",
      empty: "physiotherapies/empty",
    }),
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    afterClosed() {
      this.empty();
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // this.form.dailyTreatmentNames = this.form.dailyTreatmentNames.map(form => { return {name: form.name}});
          // this.form.products = this.form.products.map(form => form.id);
          this.form.loops = this.loops;
          this.loadingButton = true;
          this.form.patient_history_id = this.lastHistory.id;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    appendPhys() {
      this.loops.push({
        patientIndicatorNames: [{}],
      });
    },
    removePhys(index) {
      this.loops.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
.input_plusc {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-icon-plus {
    border-radius: 5px;
    border: 1px solid #dcdfe6;
    height: 32px;
    line-height: 30px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: #dcdfe6;
    cursor: pointer;
  }
}

.el-form-item {
  flex: 1;
}

.minheight .el-textarea__inner {
  min-height: 100px !important;
}

.add__likarstova {
  padding: 15px 15px 0px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}

.add_pol {
  text-align: center;
  color: #3a90c3;
  font-size: 15px;
  cursor: pointer;
}

.indicators__table .el-checkbox__label {
  font-size: 14px !important;
  color: #000 !important;
}

.indicators__table th {
  font-size: 16px !important;
  color: #000 !important;
}

.add_close {
  position: absolute;
  top: -14px;
  right: -11px;
  background: #fff;
  color: red;
  font-size: 24px;
  cursor: pointer;
}

.add_close_2 {
  position: absolute;
  top: 3px;
  right: 4px;
  background: #fff;
  color: red;
  font-size: 24px;
  cursor: pointer;
}

.color_p4 {
  margin-top: -10px;
  margin-bottom: 12px;
  font-size: 15px;
}

.button_r_2 {
  text-align: right;
  margin-top: -26px;
}
</style>